var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full lg:w-3/5 bg-white"},[_c('error-message'),(_vm.valid == 'loading')?_c('div',[_c('h2',{staticClass:"p-10 mb-40 text-2xl text-red-900 text-center"})]):(_vm.valid == 'finished')?_c('div',[(!_vm.stateFeeFound)?_c('div',{staticClass:"p-10 mb-32 text-2xl text-red-900"},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We couldn't find fees associated to this course. ")]),_c('h3',{staticClass:"text-xl"},[_vm._v(" Please try another one "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":"https://www.phlebotomyusa.com/"}},[_vm._v("HERE")]),_vm._v(". Or call "+_vm._s(_vm.phlebsPhoneNumber)+" ")])]):(_vm.courseHasStarted)?_c('div',{staticClass:"p-10 mb-32 text-2xl text-red-900"},[_c('h2',{staticClass:"mb-6"},[_vm._v(" Sorry, this class has already started ")]),(_vm.landing_page)?_c('h3',{staticClass:"text-xl"},[_vm._v(" Please try another one "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":_vm.landing_page}},[_vm._v("HERE")]),_vm._v(". Or call "+_vm._s(_vm.phlebsPhoneNumber)+" ")]):_c('h3',{staticClass:"text-xl"},[_vm._v(" Please try another one "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":"https://www.phlebotomyusa.com/"}},[_vm._v("HERE")]),_vm._v(". Or call "+_vm._s(_vm.phlebsPhoneNumber)+" ")])]):_c('div',[(_vm.courseTypeId !== 11)?_c('progress-bar',{staticClass:"mx-8 my-6",attrs:{"step":_vm.currentForm,"number-steps":_vm.numForms - 1}}):_c('progress-bar-ecg',{staticClass:"mx-8 my-6",attrs:{"step":_vm.currentForm,"number-steps":_vm.numForms - 1}}),_c('div',{staticClass:"mx-8 mt-8"},[(_vm.currentForm == _vm.numForms - 3 && _vm.courseType == 'IV')?_c('form-init-iv',{on:{"next":_vm.next}}):_vm._e(),(
            _vm.currentForm == _vm.numForms - 3 &&
            _vm.state != 'KY' &&
            _vm.currentForm == _vm.numForms - 3 &&
            _vm.state != 'TN'
          )?_c('div',{staticClass:"hidden lg:block h-56"}):_vm._e(),(
            _vm.currentForm == _vm.numForms - 2 &&
            _vm.state != 'KY' &&
            _vm.currentForm == _vm.numForms - 2 &&
            _vm.state != 'TN'
          )?_c('form-basic',{attrs:{"has-intro-question":this.hasIntroQuestion},on:{"next":_vm.next,"back":_vm.back}}):_vm._e(),(_vm.currentForm == _vm.numForms - 2 && _vm.state == 'TN')?_c('form-basic',{attrs:{"has-intro-question":this.hasIntroQuestion},on:{"next":_vm.next,"back":_vm.back}}):_vm._e(),(_vm.currentForm == _vm.numForms - 2 && _vm.state == 'KY')?_c('form-basic',{attrs:{"has-intro-question":this.hasIntroQuestion},on:{"next":_vm.next,"back":_vm.back}}):_vm._e(),(_vm.currentForm == _vm.numForms - 1)?_c('form-payment',{attrs:{"loading":_vm.loading},on:{"next":_vm.submit,"back":_vm.back}}):_vm._e(),(_vm.currentForm == _vm.numForms && !_vm.applyForApplicants)?_c('success-message'):_vm._e(),(_vm.currentForm == _vm.numForms && _vm.applyForApplicants)?_c('success-message-state-reqs'):_vm._e()],1)],1)]):(_vm.valid == 'full')?_c('div',[_c('div',{staticClass:"p-10 mb-32 text-2xl text-red-900"},[_c('h2',{staticClass:"mb-6"},[_vm._v(" Sorry, this class is sold out ")]),(_vm.landing_page)?_c('h3',{staticClass:"text-xl"},[_vm._v(" Please try another one "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":_vm.landing_page}},[_vm._v("HERE")]),_vm._v(". Or call "+_vm._s(_vm.phlebsPhoneNumber)+" ")]):_vm._e()])]):(_vm.valid == false)?_c('div',{staticClass:"p-10 mb-32 text-2xl text-red-900"},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We couldn't find that course. ")]),_c('h3',{staticClass:"text-xl"},[_vm._v(" Please try another one "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":"https://www.phlebotomyusa.com/"}},[_vm._v("HERE")]),_vm._v(". Or call "+_vm._s(_vm.phlebsPhoneNumber)+" ")])]):_vm._e()],1),_c('div',{staticClass:"hidden lg:block w-2/5"},[_c('side-bar',{attrs:{"current-form":_vm.currentForm,"num-forms":_vm.numForms}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }