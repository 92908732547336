import axios from "axios";
import { hashValue } from "@/utils";

function gtag() {
	dataLayer.push(arguments);
}
// tiktok pixel
const tiktokPixelCompletePaymentEvent = (context, identifyPayload) => {
	if (typeof ttq !== "object") {
		console.log("ttq is not defined. Tiktok Pixel code not triggered.");
		return;
	}
	const course = context.rootState.courseInformation;

	if (course.courseType !== "National") {
		console.log("ttq is not triggered. Just run on Phlebotomy Courses.");
		return;
	}
	// const newEnrollmentId = context.rootState.responseData.enrollmentId;
	// const email = state.email;
	// const

	// const identifyPayload = {
	//   email: "<hashed_email_address>", // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
	//   phone_number: "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
	//   external_id: newEnrollmentId, // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
	// };

	ttq.identify(identifyPayload);

	const paymentPayload = {
		value: context.rootGetters["courseInformation/totalPrice"], // number. Value of the order or items sold. Example: 100.
		currency: "USD", // string. The 4217 currency code. Example: "USD".
		contents: [
			{
				content_id: `${course.id}`, // string. ID of the product. Example: "1077218".
				content_name: course.formattedName, // string. The name of the page or product. Example: "shirt".
				content_type: "product", // string. Either product or product_group.
			},
		],
	};

	ttq.track("CompletePayment", paymentPayload);
};
// google ads
const phlebConfirmationInit = (context, state) => {
	console.log("context", context);
	console.log("state", state);

	const enrollmentid = context.rootState.courseInformation.id;
	const enrollment = context.rootState.courseInformation;
	const newEnrollmentId = context.rootState.responseData.enrollmentId;

	let amount = context.rootGetters["courseInformation/totalPrice"];
	// let type = 'full';
	// if(state.payFull && !state.payCustom){
	//   amount = context.rootGetters['courseInformation/totalPrice'];
	//
	// }
	// else if(!state.payFull && !state.payCustom){
	//   amount = context.rootState.courseInformation.depositAmount;
	//   type = "deposit";
	// }
	// else{
	//   amount = state.payCustomAmount;
	//   type = "partial";
	// }

	// let conversion = {
	// 	send_to: 'AW-1068002820/2GU7CIXf93sQhNyh_QM',
	// 	value: amount,
	// 	currency: 'USD',
	// 	transaction_id: '',
	// };
	// ECG
	// if( context.rootState.courseInformation.courseType == "ECG"	){
	// 	conversion = {
	// 		send_to: 'AW-11017454265/YcZ2CKfu6YEYELmFxIUp',
	// 		value: amount,
	// 		currency: 'USD',
	// 		transaction_id: '',
	// 	};
	// }
	let conversion = {
		send_to: "AW-1068002820/-zjQCNL0iZMZEITcof0D",
		value: amount,
		currency: "USD",
		transaction_id: newEnrollmentId,
	};

	gtag("event", "conversion", conversion);

	if (enrollment.courseType == "National") {
		if (typeof fbq === "function") {
			// Trigger Facebook Pixel code
			console.log("Triggered Purchase.");
			fbq("track", "Purchase", {
				value: amount,
				currency: "USD",
			});
		} else {
			console.log("fbq is not defined. Facebook Pixel code not triggered.");
		}
	}

	// gtag('event', 'Checkout', {
	//   'event_category' : 'Checkout Completed',
	//   'event_label' : window.location.href
	// });

	const postDataLayer = {
		pagePostType: "page",
		pagePostType2: "single-page",
		transactionId: enrollmentid,
		transactionType: "sale",
		transactionAffiliation: "",
		transactionTotal: amount,
		transactionShipping: "0",
		transactionTax: "0",
		transactionPaymentType: "",
		transactionCurrency: "USD",
		transactionShippingMethod: "",
		transactionPromoCode: "",
		ecommerce: {
			currencyCode: "USD",
			purchase: {
				actionField: {
					id: enrollmentid,
					affiliation: "Phlebotomy USA",
					revenue: amount,
					tax: "0",
					shipping: "0",
					action: "purchase",
				},
				products: [
					{
						id: enrollmentid,
						name: enrollment.formattedName,
						category: enrollment.courseTime,
						brand: enrollment.courseType,
						price: amount,
						currency: "USD",
						quantity: 1,
					},
				],
			},
		},
		transactionProducts: [
			{
				id: enrollmentid,
				name: enrollment.formattedName,
				category: enrollment.courseTime,
				brand: enrollment.courseType,
				price: amount,
				currency: "USD",
				quantity: 1,
			},
		],
		event: "gtm4wp.orderCompletedEEC",
		ecomm_prodid: [enrollmentid],
		ecomm_pagetype: "purchase",
		ecomm_totalvalue: 0,
		"gtm.uniqueEventId": 0,
	};

	dataLayer.push(postDataLayer);

	console.log(conversion);
	console.log(postDataLayer);

	dataLayer.push({
		page: {
			type: "confirmation", // This value must not change. Required
			environment: "production", // This value must not change. Required
		},
	});
};
// const isLocalHost = /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/.test(window.location.href);
const isLocalHost = process.env.NODE_ENV !== "production";
//console.log(process.env.NODE_ENV === 'development');
const studentData = isLocalHost
  ? {
      name: "Mike",
      lastName: "davila",
      email: "mike" + Date.now() + "@mailinator.com",
      number: "3855674394",
      dob: "04021984",

      // ssn: '1234',
      //gender: '',
    }
  : {
      name: "",
      lastName: "",
      email: "",
      number: "",
      dob: "",
      // ssn: '',
      //gender: '',
    };

const localHostData = isLocalHost
	? {
		billingAddress1: "asdf",
		billingAddress2: "asdf",
		billingCity: "asdf",
		billingState: "AK",
		billingZip: "12345",
		referredBy: "search_engine",
	}
	: {};
const studentCardPayment = isLocalHost
	? {
		billingName: "mike davila",
		billingNumber: "4111111111111111",
		//billingNumber: "",
		exp: "0325",
		code: "123",
	}
	: {
		billingName: "",
		billingNumber: "",
		exp: "",
		code: "",
	};
// initial state
const state = {
	// ***** Form1 *******
	firstTest: true,
	diplomaProof: true,
	cprCourseId: null,
	...studentData,
	// ***** Form2 *******
	// name: '',
	// lastName: '',
	// email: '',
	// number: '',
	// dob: '',
	// ssn: '',

	//  // ***** Form2 *******
	//   name: 'Mike',
	//   lastName: 'davila',
	//   email: 'mike@gmail.com',
	//   number: '8341412988',
	//   dob: '04021984',
	//   ssn: '1234',

	// ***** Form3 *******
	address: "",
	address2: "",
	city: "",
	state: "",
	zip: "",
	graduationDocName: ``,
	graduationDoc: null,
	referredBy: "",
	commentReferredBy: "",
	/* Form pre enrollment for TN state */
	tnChecklist: {
		item1: false,
		item2: false,
		item3: false,
		item4: false,
		item5: false,
		item6: false,
		item7: false,
		item8: false,
		item9: false,
		item10: false,
		studentSignature: false,
		studentDisclosureSignature: false,
	},
	/* End Form pre enrollment for TN state */

	/* Form pre enrollment for KY state */

	preEnrollment: {
		receivedInstitucionalCatalog: false,
		reviewedDescriptionProgram: false,
		understandCharges: false,
		receivedRefundSchedule: false,
		reviewedComplaintProcedure: false,
		awareProtectionFund: false,
		receivedCopyEnrollment: false,
		studentSignature: "",
	},
	/* End Form pre enrollment for KY state */

	// ***** Form4 *******
	payFull: true,
	payCustom: false,
	payCustomAmount: "",
	payCustomShowModal: false,
	paySelected: "payFull",
	...studentCardPayment,
	billingCardType: "credit", // debit/credit
	// billingName: 'mike davila',
	// billingNumber: '4111111111111111',
	// exp: '0322',
	// code: '123',
	// // ***** Form4 *******
	// payFull: true,
	// billingName: '',
	// billingNumber: '',
	// exp: '',
	// code: '',
	// type is in getter
	sameBilling: "true", // Is easier to use a string for radio buttons
	billingAddress1: "",
	billingAddress2: "",
	billingCity: "",
	billingState: "",
	billingZip: "",
	...localHostData,

	// TN Form Data Extra
	hasNationalExam: true,
	nationalExamId: null,
	trackingFields: [],
	showMobileCheckoutBreakdown: false,
};

// mutations
const mutations = {
	// ***** Form1 *******
	updateFirstTest(state, payload) {
		state.firstTest = payload;
	},
	setDiplomaProof(state, payload) {
		state.diplomaProof = payload;
	},
	// ***** Form2 *******
	updateEmail(state, payload) {
		state.email = payload;
	},
	updateName(state, payload) {
		state.name = payload;
	},
	updateLastName(state, payload) {
		state.lastName = payload;
	},
	updateNumber(state, payload) {
		state.number = payload;
	},
	updateDob(state, payload) {
		state.dob = payload;
	},
	updateSsn(state, payload) {
		state.ssn = payload;
	},
	// updateGender: (state, payload) => {
	//   state.gender = payload;
	// },
	updateGraduationDoc(state, payload) {
		state.graduationDoc = payload;
	},
	updateGraduationDocName(state, payload) {
		state.graduationDocName = payload;
	},
	updateCprCourseId: (state, payload) => {
		state.cprCourseId = payload;
	},
	// ***** Form3 *******
	updateAddress(state, payload) {
		state.address = payload;
	},
	updateAddress2(state, payload) {
		state.address2 = payload;
	},
	updateCity(state, payload) {
		state.city = payload;
	},
	updateState(state, payload) {
		state.state = payload;
	},
	updateZip(zip, payload) {
		state.zip = payload;
	},
	updateReferredBy: (state, payload) => {
		state.referredBy = payload;
	},
	updateCommentReferredBy: (state, payload) => {
		state.commentReferredBy = payload;
	},
	//  * Traking fields *
	setTrackingFields: (state, payload) => {
		state.trackingFields = payload;
	},
	// ***** Form pre enrollment for KY state *******
	updatePreEnrollmentFormField(state, payload) {
		state.preEnrollment[payload.field] = payload.value;
	},
	// ***** Form pre enrollment for TN state *******
	updateTNChecklistItem(state, payload) {
		state.tnChecklist[payload.field] = payload.value;
	},
	// ***** Form4 *******
	updatePayFull(state, payload) {
		state.payFull = payload;
	},
	setPaySelected(state, payload) {
		state.paySelected = payload;
	},
	setPayCustom(state, payload) {
		state.payCustom = payload;
	},
	setPayCustomShowModal(state, payload) {
		state.payCustomShowModal = payload;
	},
	setPayCustomAmount(state, payload) {
		state.payCustomAmount = payload;
	},
	updateBillingCardType(state, payload) {
		state.billingCardType = payload;
	},
	updateBillingName(state, payload) {
		state.billingName = payload;
	},
	updateBillingNumber(state, payload) {
		state.billingNumber = payload;
	},
	updateExp(state, payload) {
		state.exp = payload;
	},
	updateCode(state, payload) {
		state.code = payload;
	},
	updateSameBilling(state, payload) {
		state.sameBilling = payload;
	},
	updateBillingAddress1(state, payload) {
		state.billingAddress1 = payload;
	},
	updateBillingAddress2(state, payload) {
		state.billingAddress2 = payload;
	},
	updateBillingCity(state, payload) {
		state.billingCity = payload;
	},
	updateBillingState(state, payload) {
		state.billingState = payload;
	},
	updateBillingZip(state, payload) {
		state.billingZip = payload;
	},

	// TN Specific
	updateHasNationalExam(state, payload) {
		state.hasNationalExam = payload;
	},
	updateNationalExamId(state, payload) {
		state.nationalExamId = payload;
	},
	updateShowMobileCheckoutBreakdown: (state, payload) => {
		state.showMobileCheckoutBreakdown = payload;
	},
};

// getters
const getters = {
	formatedNumber: (state, getters) => {
		let value = getters.explodedNumber;
		return value ? `(${value[1]}) ${value[2]}-${value[3]}` : "";
	},
	formatedDob: (state, getters) => {
		let value = getters.explodedDob;
		return value ? `${value[1]}/${value[2]}/${value[3]}` : "";
	},
	explodedNumber: (state) => {
		//if(state.number.length == 10){
		const cleaned = ("" + state.number).replace(/\D/g, "");
		return cleaned.match(/(\d{3})(\d{3})(\d{4})/);
		//}
		//return false;
	},
	explodedDob: (state) => {
		//if(state.dob.length == 8){
		const cleaned = ("" + state.dob).replace(/\D/g, "");
		return cleaned.match(/(\d{2})(\d{2})(\d{4})/);
		//}
		//return false;
	},
	explodedExp: (state) => {
		if (state.exp.length == 4) {
			return state.exp.match(/(\d{2})(\d{2})/);
		}
		return false;
	},
	cardType: (state) => {
		let visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
		let mastercard = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
		let discover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
		let amex = /^3[47][0-9]{13}$/;

		let result = {
			name: "other",
			icon: "default",
		};
		if (visa.test(state.billingNumber)) {
			result = {
				name: "visa",
				icon: "visa",
			};
		} else if (mastercard.test(state.billingNumber)) {
			result = {
				name: "mastercard",
				icon: "mastercard-old",
			};
		} else if (discover.test(state.billingNumber)) {
			result = {
				name: "discover",
				icon: "discover",
			};
		} else if (amex.test(state.billingNumber)) {
			result = {
				name: "amex",
				icon: "amex",
			};
		}
		return result;
	},
};

// actions
const actions = {
  submitData: (context) => {
    let isTN = context.rootState.courseInformation.state == "TN";
    if (isTN) {
      // this.state == 'TN' && !this.availableForPayment
      if (context.rootState.courseInformation.availableForPayment) {
        context.dispatch(
          "basicRequest",
          process.env.VUE_APP_API_URL + "api/tn_registration_payment/process/"
        );
      } else {
        context.dispatch(
          "basicRequest",
          process.env.VUE_APP_API_URL +
            "api/tn_registration/process/" +
            context.rootState.courseInformation.id
        );
      }
    } else {
      context.dispatch(
        "basicRequest",
        process.env.VUE_APP_API_URL + "api/registration/process"
      );
    }
  },

  basicRequest: async (context, payload) => {
    let amount = 0;
    let type = "full";
    if (state.paySelected === `payFull`) {
      if (state === `TN`) {
        amount = context.rootGetters["courseInformation/withExamFee"]
          ? context.rootGetters["courseInformation/totalPrice"] +
            context.rootGetters["courseInformation/examFeeCost"]
          : context.rootGetters["courseInformation/totalPrice"];
      } else {
        amount = context.rootGetters["courseInformation/totalPrice"];
      }
    } else if (state.paySelected === `payNotFull`) {
      amount = context.rootState.courseInformation.depositAmount;
      type = "deposit";
    } else if (state.paySelected === `tnApplicacionFee`) {
      amount = context.rootState.courseInformation.tnApplicationFee;
      type = "application_fee";
    } else {
      amount = state.payCustomAmount;
      type = "partial";
    }

    const payment = {
      amount,
      payment_type: "card",
      exam_payment: state.hasNationalExam, // TN If they are going to pay the national exam
      course_id: context.rootState.courseInformation.id,
      type,
      national_exam_id: state.hasNationalExam ? state.nationalExamId : null,
      website: "tmp.localhost",
    };

    let pre_enrollment = [];
    if (context.rootState.courseInformation.state == "KY") {
      const { preEnrollment } = state;
      let index = 1;
      for (let field of Object.keys(preEnrollment)) {
        const prop = `checklist_item_${index}`;
        if (preEnrollment[field]) {
          pre_enrollment.push(prop);
        }
        // pre_enrollment.push({
        //   [prop]: preEnrollment[field]
        // });
        //console.log(field);
        index++;
      }
    }
    const explodedDob = state.dob.trim().split("/");
    const student = {
      email: state.email,
      name: state.name,
      last_name: state.lastName,
      phone1: context.getters.explodedNumber
        ? context.getters.explodedNumber[1]
        : "",
      phone2: context.getters.explodedNumber
        ? context.getters.explodedNumber[2]
        : "",
      phone3: context.getters.explodedNumber
        ? context.getters.explodedNumber[3]
        : "",
      address: state.address,
      address2: state.address2,
      city: state.city,
      state: state.state,
      zip: state.zip,
      ssn: state.ssn,
      //gender: state.gender,
      birthMonth: context.getters.explodedDob
        ? context.getters.explodedDob[1]
        : "",
      birthDay: context.getters.explodedDob
        ? context.getters.explodedDob[2]
        : "",
      birthYear: context.getters.explodedDob
        ? context.getters.explodedDob[3]
        : "",
      // birthMonth: explodedDob[0] || '',
      // birthDay: explodedDob[1] || '',
      // birthYear: explodedDob[2] || '',
      tos: true,
      cpr_course_id: state.cprCourseId || null,
      referred_by: state.referredBy,
      comment_referred_by: state.commentReferredBy || null,
    };

    const credit_card = {
      name: state.billingName,
      number: state.billingNumber,
      expiration_month: context.getters.explodedExp
        ? context.getters.explodedExp[1]
        : "",
      expiration_year: context.getters.explodedExp
        ? "20" + context.getters.explodedExp[2]
        : "",
      code: state.code,
      type: context.getters.cardType.name,
      same_billing: state.sameBilling,
      run_as: state.billingCardType,
    };

    const billing_address = {
      address: state.billingAddress1,
      address2: state.billingAddress2,
      city: state.billingCity,
      state: state.billingState,
      zip: state.billingZip,
    };

    //const tracking_fields = {}

    const post = {
      payment,
      pre_enrollment_checklist: pre_enrollment,
      student,
      credit_card,
      billing_address,
      tracking_fields: state.trackingFields,
      //discount_code: context.rootState.courseInformation.discountCode.code,
    };

    if (context.rootState.courseInformation.discountCode.isValid) {
      post.discount_code =
        context.rootState.courseInformation.discountCode.code;
    }

    //console.log(post);
    //return;

    try {
      const response = await axios.post(payload, post);

      if (response.data.success == true) {
        let data = response.data.enrollment;
        if (context.state.graduationDoc) {
          const docPost = new FormData();
          docPost.set("graduation_doc", context.state.graduationDoc);

          const responseData = await axios.post(
            process.env.VUE_APP_API_URL +
              `api/registration/student/${data.student_id}/upload_graduation_doc`,
            docPost
          );

          if (!responseData.data.success) {
            console.log(responseData.data.msg);
          }
        }

        // Set information for the successMessage page
        const { envelope_id, envelope_url } = response.data;
        const url = envelope_id
          ? envelope_url
          : `https://students.phlebotomyusa.com/account/student/${response.data.student_token}`;
        context.commit("responseData/updateLink", url, { root: true });
        context.commit("responseData/updateEnrollmentId", data.id, {
          root: true,
        });
        context.commit("responseData/updateStudentId", data.student_id, {
          root: true,
        });
        context.commit("responseData/updateDateStart", data.course.start_date, {
          root: true,
        });
        context.commit("responseData/updateDateEnd", data.course.end_date, {
          root: true,
        });
        context.commit(
          "responseData/updateTimeStart",
          data.course.hours_start,
          { root: true }
        );
        context.commit("responseData/updateTimeEnd", data.course.hours_end, {
          root: true,
        });
        context.commit("responseData/updateCity", data.course.city.name, {
          root: true,
        });
        context.commit("responseData/updateEnvelopeId", envelope_id, {
          root: true,
        });

        // Removing error if there was one before
        context.commit("errorMessage/updateStatus", false, { root: true });
        context.commit("errorMessage/updateType", "", { root: true });
        context.commit("errorMessage/updateMessage", "", { root: true });
        context.commit(
          "courseInformation/setStudentToken",
          response.data.student_token,
          { root: true }
        );
        window.scroll(0, 0);

        context.commit("checkoutData/incrementCurrentForm", null, {
          root: true,
        });

        if (process.env.NODE_ENV == "production") {
          phlebConfirmationInit(context, state);
          // console.log("google ads");
        }
        const email = await hashValue(context.state.email);
        const phone_number = await hashValue(context.state.number);
        const external_id = await hashValue(
          context.rootState.responseData.enrollmentId
        );

        const identifyPayload = {
          email,
          phone_number,
          external_id,
        };
        tiktokPixelCompletePaymentEvent(context, identifyPayload);
      } else {
        // if request comes with response error type
        if (response.data.type) {
          // showing invalid coupon error
          if (response.data.type === "invalid_coupon") {
            context.commit(
              "courseInformation/setDiscountCode",
              {
                id: null,
                amount: 0,
                isValid: false,
                error: response.data.message,
              },
              { root: true }
            );
          }
          // showing error
        } else {
          // Showing error coming from the server
          context.commit("errorMessage/updateStatus", true, { root: true });
          context.commit("errorMessage/updateType", response.data.type, {
            root: true,
          });
          context.commit("errorMessage/updateMessage", response.data.error, {
            root: true,
          });

          if (response.data?.data)
            context.commit("errorMessage/updateData", response.data.data, {
              root: true,
            });
        }

        window.scroll(0, 0);
      }

      context.commit("checkoutData/updateLoading", false, { root: true });
    } catch (error) {
      // Generice error telling to call and try again later
      context.commit("errorMessage/updateStatus", true, { root: true });
      context.commit("errorMessage/updateType", "Server", { root: true });
      context.commit(
        "errorMessage/updateMessage",
        `Please call ${process.env.VUE_APP_PHLEBS_PHONE_NUMBER} or try again later.`,
        { root: true }
      );
      // go to the top of the screen so the user can see the error
      window.scroll(0, 0);
      console.log(error);

      context.commit("checkoutData/updateLoading", false, { root: true });
    }
  },
  updateLeadOnNext: async (context) => {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}api/student/update_lead_on_next`,
        {
          email: context.state.email,
          phone: context.state.number,
          first_name: context.state.name,
          last_name: context.state.lastName,
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
