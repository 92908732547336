<template>
  <div>
    <div class="flex justify-center mt-5">
      <!-- Deposit Button -->
      <card-button @click.native="toggleButton(1)" :selected="(this.selected == 'payNotFull')"
        v-if="city !== 'rio-hondo'">
        <template v-slot:title>Pay ${{ depositAmount }} Registration</template>
        <template v-slot:header>
          <span v-if="state == 'TN'" class="py-2">
            &nbsp;
            <!-- $100 Non-refundable -->
          </span>
          <span v-else>
            Non-refundable after cancellation period
          </span>
        </template>
        <template v-slot:text> </template>
      </card-button>
      <!-- End Deposit Button -->
    </div>
    <div class="flex justify-center">
      <!-- Full Button -->
      <card-button @click.native="toggleButton(2)" :selected="this.selected == 'payFull'">
        <template v-slot:title>Pay in Full ${{ totalPayInFullAmount }}</template>
        <template v-slot:header>
          <span v-if="state == 'TN'">
            &nbsp;
            <!-- $100 Non-refundable -->
          </span>
          <span v-else>
            ${{ depositAmount }} Non-refundable after cancellation period
          </span>
          <p v-if="after_registration_fees > 0">
            &nbsp; Exam fee available after registration
          </p>
        </template>
      </card-button>
      <!-- End Full Button -->
    </div>
    <div class="flex justify-center">
      <!-- Full Button -->
      <card-button @click.native="toggleButton(3)" :selected="(this.selected == 'payCustom')"
        v-if="city !== 'rio-hondo'">
        <template v-slot:title>Pay another amount</template>
        <template v-slot:header>
          <span v-if="state == 'TN'">
            &nbsp;
            <!-- $100 Non-refundable -->
          </span>
          <span v-else> Minimum ${{ depositAmount }} registration fee </span>
        </template>
        <template v-slot:text> </template>
      </card-button>
      <!-- End Full Button -->
    </div>

  </div>

</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import CardButton from "@/components/Checkout/Elements/CardButton.vue";

export default {
  components: {
    "card-button": CardButton,
  },
  data() {
    return {
      //selected : 'payFull',
      showModal: true,
    };
  },
  created() {
    this.toggleButton(1);
  },
  computed: {
    ...mapState("courseInformation", [
      "depositAmount",
      "state",
      "city",
      "withExamFee",
      "examFeeCost",
      "availableForPayment",
      "after_registration_fees",
      "discountCode",
    ]),
    ...mapGetters("courseInformation", [
      "totalPrice",
      "totalAmount",
      "discountCodeAmount",
      "remainingBalance",
    ]),
    ...mapState('formData', ['payCustom', 'payCustomAmount', 'paySelected']),
    totalPayInFullAmount() {
      if (this.paySelected === 'payFull') return this.totalAmount - this.discountCodeAmount
      return this.totalPrice;
    },
    payFull: {
      get() {
        return this.$store.state.formData.payFull;
      },
      set(value) {
        this.$store.commit('formData/updatePayFull', value);
      }
    },
    custom: {
      get() {
        return this.payCustom;
      },
      set(value) {
        this.setPayCustom(value);
      },
    },
    customAmount: {
      get() {
        return this.payCustomAmount;
      },
      set(value) {
        this.setPayCustomAmount(value);
      },
    },
    selected: {
      get() {
        return this.paySelected;
      },
      set(value) {
        this.setPaySelected(value);
      },
    },
  },
  watch: {
    discountCode: {
      handler(newVal) {
        // if(
        //   (this.paySelected === 'payNotFull' && (newVal?.amount / 100) > this.depositAmount) ||
        //   (this.paySelected === 'payCustom' && this.totalAmount < this.depositAmount)
        // ) {
        //   // this.onPayInFull();
        // } else if(this.paySelected === 'payCustom' && this.payCustomAmount > (this.totalPrice - (newVal?.amount / 100))) {
        //   // this.setPayCustomAmount(this.totalPrice - (newVal?.amount / 100))
        //   //console.log('change custom', this.totalPrice - (newVal?.amount / 100));
        // }
        if (newVal.isValid) this.onPayInFull();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('formData', [
      'setPayCustom',
      'setPayCustomAmount',
      'setPayCustomShowModal',
      'setPaySelected'
    ]),
    ...mapMutations('courseInformation', ['setDiscountCode']),
    onPayInFull() {
      this.selected = "payFull";
      this.setPayCustomAmount("");
      this.setPayCustom(false);
      this.payFull = true;
    },
    toggleButton(event) {
      if (this.discountCode.isValid) {
        if (event === 1 && this.depositAmount >= this.remainingBalance) return; // registration only
        if (event === 3 && this.depositAmount > this.remainingBalance) return; // custom amount
      }


      if (event === 1) {
        //if(this.discountCode.isValid) return;
        this.selected = 'payNotFull';
        this.setPayCustomAmount('');
        this.setPayCustom(false);
        this.payFull = false;

      }
      // Full Pay Button Clicked
      else if (event === 2) {
        this.onPayInFull();
      }
      else if (event === 3) {
        this.selected = 'payCustom';
        this.setPayCustomShowModal(true);
        //this.setPayCustomShowModal(false);
        this.setPayCustomAmount('');
        this.setPayCustom(false);

      }
    }
  }
}
</script>

<style scoped></style>
